<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$router.back()">
            <ion-icon :icon="chevronBackSharp"></ion-icon> Retour
          </ion-button>
        </ion-buttons>
        <ion-title>{{qualite.libelle}}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-text>
      <h1 class="ion-margin">
        <!-- <div class="subtitle">Liste des métiers correspondants à</div> -->
        <span class="title">{{qualite.libelle}}</span>
      </h1>
      </ion-text>
      <ListeMetiers
        titre="Liste des métiers correspondants"
        v-bind:metiers="qualite.metiers"
      />

      <created-by />
  </ion-content>
  </ion-page>
</template>

<script>
import ListeMetiers from '../components/ListeMetiers'
import CreatedBy from '@/components/CreatedBy'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/vue";
import {chevronBackSharp} from "ionicons/icons";
import {mapGetters} from "vuex";
export default {
  name: 'PageDetailQualite',
  components: {
    CreatedBy,
    ListeMetiers,
    IonContent,
    IonHeader,
    IonPage,
    IonIcon,
    IonText,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
  },
  setup() {
    return { chevronBackSharp };
  },
  props: {
    id: {
      required: true
      // type: Number
    }
  },
  computed: {
    ...mapGetters(['getQualite']),
    qualite() {
      return this.getQualite(this.id)
    }
  },
}
</script>

<style scoped>
  h1 .subtitle {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 4px;
  }

  h1 .title {
    color: var(--ion-color-dark);
    font-size: 28px;
    font-weight: 700;
    line-height: 1.2;
  }
</style>
